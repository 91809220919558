import { useEffect } from 'react';
import './location.css';
import RunnerUp from './../../components/runnerup/runnerup';
import {Link} from "react-router-dom";
function Location() {

  useEffect(() => {
  document.title = "Our Locations";
  window.scrollTo(0, 0)
  }, [])

  return (
    <div className="location">
    <div className="first">
      <h1>Find Curry On Naanstop</h1>
    </div>

{/*     <div className="location_container" id="location1">

      <h2>7 Dials Market</h2>
      <Link to='/7dial'><button type="button" name="button">CLICK HERE</button></Link>

    </div>
 */}




{/*     <div className="location_container" id="location2">

      <h2>Cucumber Alley</h2>
      <Link to='/cucumber-alley'><button type="button" name="button">CLICK HERE</button></Link>

    </div>
 */}




    <div className="location_container" id="location3">

      <h2>Herne Hill</h2>
      <Link to='/hernehill'><button type="button" name="button">CLICK HERE</button></Link>

    </div>


{/*     <div className="location_container" id="location5">

      <h2>Market Place - Vauxhall</h2>
      <Link to='/vauxhall'><button type="button" name="button">CLICK HERE</button></Link>

    </div>
 */}

<div className="location_container" id="location6">

<h2>BatterSea</h2>
<Link to='/battersea'><button type="button" name="button">CLICK HERE</button></Link>

</div>

<div className="location_container" id="location7">

<h2>Canning Town</h2>
<Link to='/canningtown'><button type="button" name="button">CLICK HERE</button></Link>

</div>

{/* 
<div className="location_container" id="location8">

<h2>Camden Lock</h2>
<Link to='/camden'><button type="button" name="button">CLICK HERE</button></Link>

</div>
 */}

<div className="location_container" id="location9">

<h2>Box Park Croydon</h2>
<Link to='/croydon'><button type="button" name="button">CLICK HERE</button></Link>

</div>


    <RunnerUp />
    </div>
  )
}


export default Location;
