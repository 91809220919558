import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import Location from "./pages/location/location";
import Menu from "./pages/takeaway_and_menu/menu";
import Hernehill from "./pages/eachlocation/hernehill";
import Contact from "./pages/contact/contact";
import FoodTruck from "./pages/contact/foodtruck";
import Battersea from "./pages/eachlocation/battersea";
import Canningtown from "./pages/eachlocation/canningtown";
import Croydon from "./pages/eachlocation/croydon";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TiffinClub from "./pages/tiffinclub/tiffinclub";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-locations" element={<Location />} />
          <Route path="/food-truck" element={<FoodTruck />} />
          <Route path="/our-menu" element={<Menu />} />
          <Route path="/hernehill" element={<Hernehill />} />
          <Route path="/battersea" element={<Battersea />} />
          <Route path="/canningtown" element={<Canningtown />} />
          <Route path="/croydon" element={<Croydon />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/tiffinclub" element={<TiffinClub />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
